

















import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'OleMissL9PostLabPart1A',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part1: null,
      },
      questions: [
        {
          text: 'When discussing how to determine if there was a correlation between shoe size and height, how did you decide which data you needed to gather?',
          inputName: 'part1',
        },
      ],
    };
  },
};
